import LazyLoad from "vanilla-lazyload"
import Bowser from "bowser";
import '../css/app.scss'

window.LazyImages = new LazyLoad({
  elements_selector: ".lazy",
});

window.getScheme = function() {
  if (Bowser.ios) {
    if (['dev.aukana.jp', 'stg.aukana.jp'].indexOf(location.hostname)>-1) {
      return "aukana-jp-stage"
    } else {
      return "aukana-jp"
    }
  } else {
    return "aukana-jp"
  }
}

/** No longer works */
// window.openDeepLink = function(pathname) {
//   let scheme = getScheme();
//   let url = `${scheme}://${location.hostname}${pathname || location.pathname}`;
//   location.href = url;
//   return false;
// }

document.querySelectorAll("[data-deeplink-path]").forEach(elem => {
  const scheme = getScheme()
  const pathname = elem.getAttribute("data-deeplink-path")
  const url = `${scheme}://${location.hostname}${pathname || location.pathname}`;
  elem.setAttribute("href", url)
})